var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"router-view-container"},[_c('div',{staticClass:"flex items-center space-x-4 mb-5"},[_c('vs-button',{attrs:{"border":"","size":"large"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('svg-icon',{attrs:{"icon-class":"arrow_left","className":"primary"}})],1),_c('h1',{staticClass:"page_title"},[_vm._v(_vm._s(_vm.$route.meta.title))]),_c('img',{staticClass:"w-32",attrs:{"src":require("@/assets/images/payments/ecpay_logo.png"),"alt":"ecpay"}})],1),(_vm.is_API_loaded)?_c('div',{staticClass:"space-y-10"},[_c('section',{staticClass:"wrap"},[_c('div',{staticClass:"leftBlock"},[_c('h2',{staticClass:"h2 sm:mt-4"},[_vm._v("物流參數設定")])]),_c('div',{staticClass:"rightBlock"},[_c('div',[_c('section',{staticClass:"grid grid-cols-1 gap-6"},[_c('div',[_c('div',{staticClass:"flex items-center justify-between mb-1"},[_c('h4',{staticClass:"h4"},[_vm._v("商店代號 *")])]),_c('ValidationProvider',{staticClass:"w-full relative",attrs:{"tag":"div","mode":"aggressive","name":"商店代號","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{model:{value:(_vm.setting.sid),callback:function ($$v) {_vm.$set(_vm.setting, "sid", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"setting.sid"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',[_c('div',{staticClass:"flex items-center justify-between mb-1"},[_c('h4',{staticClass:"h4"},[_vm._v("物流介接 HashKey *")])]),_c('ValidationProvider',{staticClass:"w-full relative",attrs:{"tag":"div","mode":"aggressive","name":"物流介接 HashKey","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{model:{value:(_vm.setting.hashkey),callback:function ($$v) {_vm.$set(_vm.setting, "hashkey", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"setting.hashkey"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',[_c('div',{staticClass:"flex items-center justify-between mb-1"},[_c('h4',{staticClass:"h4"},[_vm._v("物流介接 HashIV *")])]),_c('ValidationProvider',{staticClass:"w-full relative",attrs:{"tag":"div","mode":"aggressive","name":"物流介接 HashIV","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{model:{value:(_vm.setting.hashiv),callback:function ($$v) {_vm.$set(_vm.setting, "hashiv", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"setting.hashiv"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('hr'),_c('div',[_c('h3',{staticClass:"h3 mb-6"},[_vm._v("黑貓宅急便必填資料，欲開啟黑貓宅急便才需填寫以下欄位！")]),_c('div',{staticClass:"grid gap-6 grid-cols-2"},[_c('div',{staticClass:"relative w-full"},[_c('div',{staticClass:"flex items-center justify-between mb-1"},[_c('h4',{staticClass:"h4"},[_vm._v("寄件人姓名")])]),_c('ValidationProvider',{attrs:{"name":"真實寄件人姓名","rules":((_vm._t_cat_isRequired ? 'required' : '') + "|max:20")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{model:{value:(_vm.setting.uname),callback:function ($$v) {_vm.$set(_vm.setting, "uname", $$v)},expression:"setting.uname"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"relative w-full"},[_c('div',{staticClass:"flex items-center justify-between mb-1"},[_c('h4',{staticClass:"h4"},[_vm._v("寄件人手機號碼")])]),_c('ValidationProvider',{attrs:{"name":"寄件人手機號碼","rules":((_vm._t_cat_isRequired ? 'required' : '') + "|TW_phoneNum")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{model:{value:(_vm.setting.mp),callback:function ($$v) {_vm.$set(_vm.setting, "mp", $$v)},expression:"setting.mp"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"relative w-full"},[_c('div',{staticClass:"flex items-center justify-between mb-1"},[_c('h4',{staticClass:"h4"},[_vm._v("國家/地區")])]),_c('ValidationProvider',{attrs:{"name":"國家/地區","rules":("" + (_vm._t_cat_isRequired ? 'required' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-select',{staticClass:"w-full",model:{value:(_vm.setting.rshit),callback:function ($$v) {_vm.$set(_vm.setting, "rshit", $$v)},expression:"setting.rshit"}},[_c('vs-option',{attrs:{"label":"台灣","value":"台灣"}},[_vm._v("台灣")])],1),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"relative w-full"},[_c('div',{staticClass:"flex items-center justify-between mb-1"},[_c('h4',{staticClass:"h4"},[_vm._v("縣市")])]),_c('ValidationProvider',{attrs:{"name":"縣市","rules":("" + (_vm._t_cat_isRequired ? 'required' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-select',{staticClass:"w-full",on:{"change":_vm.cityHandler},model:{value:(_vm.setting.rcity),callback:function ($$v) {_vm.$set(_vm.setting, "rcity", $$v)},expression:"setting.rcity"}},_vm._l((_vm.TW_districts),function(city){return _c('vs-option',{key:city.name,attrs:{"label":city.name,"value":city.name}},[_vm._v(_vm._s(city.name))])}),1),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"relative w-full"},[_c('div',{staticClass:"flex items-center justify-between mb-1"},[_c('h4',{staticClass:"h4"},[_vm._v("鄉鎮市區")])]),_c('ValidationProvider',{attrs:{"name":"鄉鎮市區","rules":("" + (_vm._t_cat_isRequired ? 'required' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm._district)?_c('vs-select',{key:_vm._district.districts.length,staticClass:"w-full",on:{"change":_vm.districtHandler},model:{value:(_vm.setting.rarea),callback:function ($$v) {_vm.$set(_vm.setting, "rarea", $$v)},expression:"setting.rarea"}},_vm._l((_vm._district.districts),function(district){return _c('vs-option',{key:district.name,attrs:{"label":district.name,"value":district.name}},[_vm._v(_vm._s(district.name))])}),1):_c('vs-select',{model:{value:(_vm.setting.rarea),callback:function ($$v) {_vm.$set(_vm.setting, "rarea", $$v)},expression:"setting.rarea"}},[_c('vs-option',{attrs:{"disabled":"","label":"請先選擇縣市","value":"請先選擇縣市"}},[_vm._v("請先選擇縣市")])],1),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"relative w-full"},[_c('div',{staticClass:"flex items-center justify-between mb-1"},[_c('h4',{staticClass:"h4"},[_vm._v("郵遞區號")])]),_c('ValidationProvider',{attrs:{"name":"郵遞區號","rules":((_vm._t_cat_isRequired ? 'required' : '') + "|max:20")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{model:{value:(_vm.setting.rzipcode),callback:function ($$v) {_vm.$set(_vm.setting, "rzipcode", $$v)},expression:"setting.rzipcode"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"relative pt-4"},[_c('div',{staticClass:"flex items-center justify-between mb-1"},[_c('h4',{staticClass:"h4"},[_vm._v("地址")])]),_c('ValidationProvider',{attrs:{"name":"地址","rules":((_vm._t_cat_isRequired ? 'required' : '') + "|max:100")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{model:{value:(_vm.setting.raddress1),callback:function ($$v) {_vm.$set(_vm.setting, "raddress1", $$v)},expression:"setting.raddress1"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])])])])]),_c('hr'),_c('section',{staticClass:"wrap"},[_c('div',{staticClass:"leftBlock"},[_c('h2',{staticClass:"h2 sm:mt-4"},[_vm._v("運送方式")])]),_c('div',{staticClass:"rightBlock",staticStyle:{"padding":"16px 0"}},[_c('div',_vm._l((_vm.shippingList),function(item,index){return _c('collapse-item',{key:index,staticClass:"px-4",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"flex items-center space-x-2"},[_c('vs-checkbox',{attrs:{"success":""},on:{"click":function($event){$event.stopPropagation();}},model:{value:(_vm.checkbox[item.chkName]),callback:function ($$v) {_vm.$set(_vm.checkbox, item.chkName, $$v)},expression:"checkbox[item.chkName]"}}),_c('p',[_vm._v(_vm._s(_vm.logisticsMixin(item.logisticMixin_ID))+"("+_vm._s(item.cod)+")")])],1)]},proxy:true}],null,true)},[_c('section',{staticClass:"py-4 pl-8 space-y-6"},[_c('section',[_c('div',{staticClass:"flex items-center justify-between mb-1"},[_c('h4',{staticClass:"h4"},[_vm._v("顯示的運送名稱")]),_c('p',{staticClass:"text-gray-400"},[_vm._v("※ 會顯示於結帳頁面上。")])]),_c('ValidationProvider',{staticClass:"w-full relative",attrs:{"tag":"div","name":"顯示的運送名稱","vid":item.vid,"rules":((_vm.checkbox[item.chkName] ? 'required' : '') + "|max:30")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{attrs:{"maxlength":"30","placeholder":((_vm.logisticsMixin(item.logisticMixin_ID)) + "(" + (item.cod) + ")")},model:{value:(item.data.title),callback:function ($$v) {_vm.$set(item.data, "title", $$v)},expression:"item.data.title"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('section',[_c('h4',{staticClass:"h4 mb-1"},[_vm._v("運費")]),_c('ValidationProvider',{staticClass:"w-full relative",attrs:{"tag":"div","name":"運費","vid":item.vid + 'cost',"rules":((_vm.checkbox[item.chkName] ? 'required' : '') + "|numeric|min_value:0")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"flex items-center space-x-2 w-full"},[_c('p',[_vm._v("NT$")]),_c('vs-input',{staticClass:"w-full",attrs:{"type":"number","placeholder":"0"},model:{value:(item.data.lpays),callback:function ($$v) {_vm.$set(item.data, "lpays", _vm._n($$v))},expression:"item.data.lpays"}})],1),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('section',[_c('div',{staticClass:"flex items-center justify-between mb-1"},[_c('h4',{staticClass:"h4"},[_vm._v("運送說明")]),_c('p',{staticClass:"text-gray-400"},[_vm._v("※ 會顯示於結帳頁面上。")])]),_c('ValidationProvider',{staticClass:"w-full relative",attrs:{"tag":"div","name":"運送說明","vid":item.vid + 'textarea',"rules":"max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('myTextarea',{attrs:{"placeholder":"填寫顧客於結帳時的提示或說明","height":"100px","maxlength":"200"},model:{value:(item.data.note),callback:function ($$v) {_vm.$set(item.data, "note", $$v)},expression:"item.data.note"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])])}),1)])]),_c('hr'),_c('div',{staticClass:"flex items-center justify-between"},[_c('router-link',{attrs:{"to":{ name: 'store_shipping' }}},[_c('vs-button',{attrs:{"size":"large","border":""}},[_vm._v("取消")])],1),_c('vs-button',{ref:"saveBtn",attrs:{"disabled":invalid,"size":"large"},on:{"click":function($event){return _vm.saveEdit(invalid)}}},[_vm._v("儲存")])],1)]):_c('div',{staticClass:"space-y-4"},_vm._l((2),function(item,index){return _c('section',{key:index,staticClass:"wrap"},[_c('div',{staticClass:"leftBlock"},[_c('skeleton',{staticClass:"mt-4",attrs:{"width":"100px"}}),_c('skeleton',{attrs:{"width":"200px","height":"10px"}}),_c('skeleton',{attrs:{"width":"200px","height":"10px"}}),_c('skeleton',{attrs:{"width":"200px","height":"10px"}})],1),_c('div',{staticClass:"rightBlock"},[_c('div',{staticClass:"flex items-center justify-between"},[_c('skeleton',{attrs:{"width":"100px"}}),_c('skeleton',{attrs:{"width":"100px"}})],1),_c('div',{staticClass:"flex items-start flex-col space-y-2"},_vm._l((3),function(item,index){return _c('skeleton',{key:index,attrs:{"width":"200px"}})}),1)])])}),0)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }