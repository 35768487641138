<template>
  <div>
    <ValidationObserver v-slot="{ invalid }">
      <div class="router-view-container">
        <div class="flex items-center space-x-4 mb-5">
          <vs-button @click="$router.go(-1)" border size="large">
            <svg-icon icon-class="arrow_left" className="primary"></svg-icon>
          </vs-button>
          <h1 class="page_title">{{ $route.meta.title }}</h1>
          <img class="w-32" src="@/assets/images/payments/ecpay_logo.png" alt="ecpay" />
        </div>

        <div v-if="is_API_loaded" class="space-y-10">
          <section class="wrap">
            <div class="leftBlock">
              <h2 class="h2 sm:mt-4">物流參數設定</h2>
            </div>
            <div class="rightBlock">
              <div>
                <section class="grid grid-cols-1 gap-6">
                  <div>
                    <div class="flex items-center justify-between mb-1">
                      <h4 class="h4">商店代號 *</h4>
                    </div>
                    <ValidationProvider
                      tag="div"
                      class="w-full relative"
                      mode="aggressive"
                      name="商店代號"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <vs-input v-model.trim="setting.sid"></vs-input>
                      <errorDiv>{{ errors[0] }}</errorDiv>
                    </ValidationProvider>
                  </div>
                  <div>
                    <div class="flex items-center justify-between mb-1">
                      <h4 class="h4">物流介接 HashKey *</h4>
                    </div>
                    <ValidationProvider
                      tag="div"
                      class="w-full relative"
                      mode="aggressive"
                      name="物流介接 HashKey"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <vs-input v-model.trim="setting.hashkey"></vs-input>
                      <errorDiv>{{ errors[0] }}</errorDiv>
                    </ValidationProvider>
                  </div>
                  <div>
                    <div class="flex items-center justify-between mb-1">
                      <h4 class="h4">物流介接 HashIV *</h4>
                    </div>
                    <ValidationProvider
                      tag="div"
                      class="w-full relative"
                      mode="aggressive"
                      name="物流介接 HashIV"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <vs-input v-model.trim="setting.hashiv"></vs-input>
                      <errorDiv>{{ errors[0] }}</errorDiv>
                    </ValidationProvider>
                  </div>
                  <hr />
                  <div>
                    <h3 class="h3 mb-6">黑貓宅急便必填資料，欲開啟黑貓宅急便才需填寫以下欄位！</h3>
                    <div class="grid gap-6 grid-cols-2">
                      <div class="relative w-full">
                        <div class="flex items-center justify-between mb-1">
                          <h4 class="h4">寄件人姓名</h4>
                        </div>
                        <ValidationProvider
                          name="真實寄件人姓名"
                          :rules="`${_t_cat_isRequired ? 'required' : ''}|max:20`"
                          v-slot="{ errors }"
                        >
                          <vs-input v-model="setting.uname"> </vs-input>
                          <errorDiv>{{ errors[0] }}</errorDiv>
                        </ValidationProvider>
                      </div>
                      <div class="relative w-full">
                        <div class="flex items-center justify-between mb-1">
                          <h4 class="h4">寄件人手機號碼</h4>
                        </div>

                        <ValidationProvider
                          name="寄件人手機號碼"
                          :rules="`${_t_cat_isRequired ? 'required' : ''}|TW_phoneNum`"
                          v-slot="{ errors }"
                        >
                          <vs-input v-model="setting.mp"> </vs-input>
                          <errorDiv>{{ errors[0] }}</errorDiv>
                        </ValidationProvider>
                      </div>
                      <div class="relative w-full">
                        <div class="flex items-center justify-between mb-1">
                          <h4 class="h4">國家/地區</h4>
                        </div>

                        <ValidationProvider name="國家/地區" :rules="`${_t_cat_isRequired ? 'required' : ''}`" v-slot="{ errors }">
                          <vs-select class="w-full" v-model="setting.rshit">
                            <vs-option label="台灣" value="台灣">台灣</vs-option>
                          </vs-select>
                          <errorDiv>{{ errors[0] }}</errorDiv>
                        </ValidationProvider>
                      </div>
                      <div class="relative w-full">
                        <div class="flex items-center justify-between mb-1">
                          <h4 class="h4">縣市</h4>
                        </div>

                        <ValidationProvider name="縣市" :rules="`${_t_cat_isRequired ? 'required' : ''}`" v-slot="{ errors }">
                          <!-- 縣市(若為台灣，選單) -->
                          <vs-select @change="cityHandler" class="w-full" v-model="setting.rcity">
                            <vs-option v-for="city in TW_districts" :key="city.name" :label="city.name" :value="city.name">{{
                              city.name
                            }}</vs-option>
                          </vs-select>

                          <errorDiv>{{ errors[0] }}</errorDiv>
                        </ValidationProvider>
                      </div>

                      <div class="relative w-full">
                        <div class="flex items-center justify-between mb-1">
                          <h4 class="h4">鄉鎮市區</h4>
                        </div>

                        <ValidationProvider name="鄉鎮市區" :rules="`${_t_cat_isRequired ? 'required' : ''}`" v-slot="{ errors }">
                          <!-- 鄉鎮市區(若為台灣，選單) -->
                          <vs-select
                            :key="_district.districts.length"
                            v-if="_district"
                            @change="districtHandler"
                            class="w-full"
                            v-model="setting.rarea"
                          >
                            <vs-option
                              v-for="district in _district.districts"
                              :key="district.name"
                              :label="district.name"
                              :value="district.name"
                              >{{ district.name }}</vs-option
                            >
                          </vs-select>

                          <vs-select v-else v-model="setting.rarea">
                            <vs-option disabled label="請先選擇縣市" value="請先選擇縣市">請先選擇縣市</vs-option>
                          </vs-select>

                          <errorDiv>{{ errors[0] }}</errorDiv>
                        </ValidationProvider>
                      </div>
                      <div class="relative w-full">
                        <div class="flex items-center justify-between mb-1">
                          <h4 class="h4">郵遞區號</h4>
                        </div>
                        <ValidationProvider name="郵遞區號" :rules="`${_t_cat_isRequired ? 'required' : ''}|max:20`" v-slot="{ errors }">
                          <vs-input v-model="setting.rzipcode"> </vs-input>
                          <errorDiv>{{ errors[0] }}</errorDiv>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="relative pt-4">
                      <div class="flex items-center justify-between mb-1">
                        <h4 class="h4">地址</h4>
                      </div>

                      <ValidationProvider name="地址" :rules="`${_t_cat_isRequired ? 'required' : ''}|max:100`" v-slot="{ errors }">
                        <vs-input v-model="setting.raddress1"> </vs-input>
                        <errorDiv>{{ errors[0] }}</errorDiv>
                      </ValidationProvider>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </section>
          <hr />

          <section class="wrap">
            <div class="leftBlock">
              <h2 class="h2 sm:mt-4">運送方式</h2>
            </div>
            <div class="rightBlock" style="padding: 16px 0">
              <div>
                <collapse-item class="px-4" v-for="(item, index) in shippingList" :key="index">
                  <template #title>
                    <div class="flex items-center space-x-2">
                      <vs-checkbox success v-model="checkbox[item.chkName]" @click.stop=""></vs-checkbox>
                      <p>{{ logisticsMixin(item.logisticMixin_ID) }}({{ item.cod }})</p>
                    </div>
                  </template>
                  <section class="py-4 pl-8 space-y-6">
                    <section>
                      <div class="flex items-center justify-between mb-1">
                        <h4 class="h4">顯示的運送名稱</h4>
                        <p class="text-gray-400">※ 會顯示於結帳頁面上。</p>
                      </div>
                      <ValidationProvider
                        tag="div"
                        class="w-full relative"
                        name="顯示的運送名稱"
                        :vid="item.vid"
                        :rules="`${checkbox[item.chkName] ? 'required' : ''}|max:30`"
                        v-slot="{ errors }"
                      >
                        <vs-input
                          v-model="item.data.title"
                          maxlength="30"
                          :placeholder="`${logisticsMixin(item.logisticMixin_ID)}(${item.cod})`"
                        ></vs-input>
                        <errorDiv>{{ errors[0] }}</errorDiv>
                      </ValidationProvider>
                    </section>

                    <section>
                      <h4 class="h4 mb-1">運費</h4>
                      <ValidationProvider
                        tag="div"
                        class="w-full relative"
                        name="運費"
                        :vid="item.vid + 'cost'"
                        :rules="`${checkbox[item.chkName] ? 'required' : ''}|numeric|min_value:0`"
                        v-slot="{ errors }"
                      >
                        <div class="flex items-center space-x-2 w-full">
                          <p>NT$</p>
                          <vs-input class="w-full" type="number" v-model.number="item.data.lpays" placeholder="0"></vs-input>
                        </div>
                        <errorDiv>{{ errors[0] }}</errorDiv>
                      </ValidationProvider>
                    </section>

                    <section>
                      <div class="flex items-center justify-between mb-1">
                        <h4 class="h4">運送說明</h4>
                        <p class="text-gray-400">※ 會顯示於結帳頁面上。</p>
                      </div>
                      <ValidationProvider
                        tag="div"
                        class="w-full relative"
                        name="運送說明"
                        :vid="item.vid + 'textarea'"
                        rules="max:200"
                        v-slot="{ errors }"
                      >
                        <myTextarea
                          placeholder="填寫顧客於結帳時的提示或說明"
                          height="100px"
                          v-model="item.data.note"
                          maxlength="200"
                        ></myTextarea>
                        <errorDiv>{{ errors[0] }}</errorDiv>
                      </ValidationProvider>
                    </section>
                  </section>
                </collapse-item>
              </div>
            </div>
          </section>
          <hr />
          <div class="flex items-center justify-between">
            <router-link :to="{ name: 'store_shipping' }">
              <vs-button size="large" border>取消</vs-button>
            </router-link>

            <vs-button :disabled="invalid" size="large" ref="saveBtn" @click="saveEdit(invalid)">儲存</vs-button>
          </div>
        </div>

        <!-- 骨架屏 -->
        <div class="space-y-4" v-else>
          <section v-for="(item, index) in 2" :key="index" class="wrap">
            <div class="leftBlock">
              <skeleton class="mt-4" width="100px" />
              <skeleton width="200px" height="10px" />
              <skeleton width="200px" height="10px" />
              <skeleton width="200px" height="10px" />
            </div>
            <div class="rightBlock">
              <div class="flex items-center justify-between">
                <skeleton width="100px" />
                <skeleton width="100px" />
              </div>
              <div class="flex items-start flex-col space-y-2">
                <skeleton v-for="(item, index) in 3" :key="index" width="200px" />
              </div>
            </div>
          </section>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import TW_districts from '@/assets/json/TW_districts.json' // 台灣縣市 郵遞區號 列表
import collapseItem from '@/components/ui_components/collapse-item'
import shipping from '@/assets/mixins/shipping.js'
export default {
  name: 'ecpay-shipping',
  mixins: [shipping],
  components: {
    collapseItem,
  },
  data() {
    return {
      is_API_loaded: false,
      setting: {},
      checkbox: {
        seven_eleven: false,
        seven_eleven_COD: false,
        family_mart: false,
        family_mart_COD: false,
        hi_life: false,
        hi_life_COD: false,
        ok: false,
        ok_COD: false,
        t_cat_1: false,
        t_cat_2: false,
        t_cat_3: false,
      },
      shippingList: {
        seven_eleven: {
          logisticMixin_ID: 1,
          vid: '1',
          cod: '純取貨',
          chkName: 'seven_eleven',
          data: {
            pterms: 0,
            shit: '台灣 Taiwan',
            land: 0,
            logistics: 1,
            title: '7-ELEVEN交貨便(純取貨)',
            temp: 1,
            lpays: 60,
            note: '',
          },
        },
        seven_eleven_COD: {
          logisticMixin_ID: 1,
          vid: '2',
          cod: '取貨付款',
          chkName: 'seven_eleven_COD',
          data: {
            pterms: 6,
            shit: '台灣 Taiwan',
            land: 0,
            logistics: 1,
            title: '7-ELEVEN交貨便(取貨付款)',
            temp: 1,
            lpays: 60,
            note: '',
          },
        },
        family_mart: {
          logisticMixin_ID: 2,
          vid: '3',
          cod: '純取貨',
          chkName: 'family_mart',
          data: {
            pterms: 0,
            shit: '台灣 Taiwan',
            land: 0,
            logistics: 2,
            title: '全家店到店(純取貨)',
            temp: 1,
            lpays: 60,
            note: '',
          },
        },
        family_mart_COD: {
          logisticMixin_ID: 2,
          vid: '4',
          cod: '取貨付款',
          chkName: 'family_mart_COD',
          data: {
            pterms: 6,
            shit: '台灣 Taiwan',
            land: 0,
            logistics: 2,
            title: '全家店到店(取貨付款)',
            temp: 1,
            lpays: 60,
            note: '',
          },
        },
        hi_life: {
          logisticMixin_ID: 3,
          vid: '5',
          cod: '純取貨',
          chkName: 'hi_life',
          data: {
            pterms: 0,
            shit: '台灣 Taiwan',
            land: 0,
            logistics: 3,
            title: '萊爾富店到店(純取貨)',
            temp: 1,
            lpays: 60,
            note: '',
          },
        },
        hi_life_COD: {
          logisticMixin_ID: 3,
          vid: '6',
          cod: '取貨付款',
          chkName: 'hi_life_COD',
          data: {
            pterms: 6,
            shit: '台灣 Taiwan',
            land: 0,
            logistics: 3,
            title: '萊爾富店到店(取貨付款)',
            temp: 1,
            lpays: 60,
            note: '',
          },
        },
        ok: {
          logisticMixin_ID: 4,
          vid: '7',
          cod: '純取貨',
          chkName: 'ok',
          data: {
            pterms: 0,
            shit: '台灣 Taiwan',
            land: 0,
            logistics: 4,
            title: 'OK店到店(純取貨)',
            temp: 1,
            lpays: 60,
            note: '',
          },
        },
        ok_COD: {
          logisticMixin_ID: 4,
          vid: '8',
          cod: '取貨付款',
          chkName: 'ok_COD',
          data: {
            pterms: 6,
            shit: '台灣 Taiwan',
            land: 0,
            logistics: 4,
            title: 'OK店到店(取貨付款)',
            temp: 1,
            lpays: 60,
            note: '',
          },
        },
        t_cat_1: {
          logisticMixin_ID: 5,
          vid: '9',
          cod: '一般常溫',
          chkName: 't_cat_1',
          data: {
            pterms: 0,
            shit: '台灣 Taiwan',
            land: 0,
            logistics: 5,
            title: '黑貓宅急便(一般常溫)',
            temp: 1,
            lpays: 0,
            note: '',
          },
        },
        t_cat_2: {
          logisticMixin_ID: 5,
          vid: '10',
          cod: '冷藏',
          chkName: 't_cat_2',
          data: {
            pterms: 0,
            shit: '台灣 Taiwan',
            land: 0,
            logistics: 5,
            title: '黑貓宅急便(冷藏)',
            temp: 2,
            lpays: 0,
            note: '',
          },
        },
        t_cat_3: {
          logisticMixin_ID: 5,
          vid: '11',
          cod: '冷凍',
          chkName: 't_cat_3',
          data: {
            pterms: 0,
            shit: '台灣 Taiwan',
            land: 0,
            logistics: 5,
            title: '黑貓宅急便(冷凍)',
            temp: 3,
            lpays: 0,
            note: '',
          },
        },
      },
      TW_districts: TW_districts, // 台灣縣市 郵遞區號 列表
    }
  },
  computed: {
    // 篩選出鄉鎮市區
    _district() {
      let city = this.setting.rcity

      if (city) return this.TW_districts.find((item) => item.name === city)
      else return null
    },
    // 判斷黑貓必填資料要不要必填
    _t_cat_isRequired() {
      if (this.checkbox.t_cat_1 || this.checkbox.t_cat_3 || this.checkbox.t_cat_3) return true
      else return false
    },
  },
  created() {
    this.loadSetting()
  },
  methods: {
    // 4.4.2.6 物流第三方資料-讀取
    loadSetting() {
      this.$axios({
        url: 'front/set/app/getLogisticsThirdData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          lthird: 1, // 讀取綠界
        },
      }).then((res) => {
        if (res.data.Success) {
          const allDataAry = res.data.Data.alldata

          allDataAry.forEach((item) => {
            // 判斷運送方式 1 = 統一超商
            if (item.logistics === 1) {
              // 判斷是不是貨到付款 6 貨到付款 0 無
              if (item.pterms === 6) {
                this.shippingList.seven_eleven_COD.data = item
                this.checkbox.seven_eleven_COD = true
              } else if (item.pterms === 0) {
                this.shippingList.seven_eleven.data = item
                this.checkbox.seven_eleven = true
              }
            } else if (item.logistics === 2) {
              if (item.pterms === 6) {
                this.shippingList.family_mart_COD.data = item
                this.checkbox.family_mart_COD = true
              } else if (item.pterms === 0) {
                this.shippingList.family_mart.data = item
                this.checkbox.family_mart = true
              }
            } else if (item.logistics === 3) {
              if (item.pterms === 6) {
                this.shippingList.hi_life_COD.data = item
                this.checkbox.hi_life_COD = true
              } else if (item.pterms === 0) {
                this.shippingList.hi_life.data = item
                this.checkbox.hi_life = true
              }
            } else if (item.logistics === 4) {
              if (item.pterms === 6) {
                this.shippingList.ok_COD.data = item
                this.checkbox.ok_COD = true
              } else if (item.pterms === 0) {
                this.shippingList.ok.data = item
                this.checkbox.ok = true
              }
            } else if (item.logistics === 5) {
              // 黑貓要判斷溫層 1 常溫 2 冷藏 3 冷凍

              if (item.temp === 1) {
                this.shippingList.t_cat_1.data = item
                this.checkbox.t_cat_1 = true
              } else if (item.temp === 2) {
                this.shippingList.t_cat_2.data = item
                this.checkbox.t_cat_2 = true
              } else if (item.temp === 3) {
                this.shippingList.t_cat_3.data = item
                this.checkbox.t_cat_3 = true
              }
            }
          })

          this.setting = res.data.Data
          this.is_API_loaded = true
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },

    // 4.4.2.7 物流第三方資料-修改
    saveEdit(invalid) {
      if (invalid) return
      const loading = this.$vs.loading({
        target: this.$refs.saveBtn,
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      let allData = []
      for (let key in this.checkbox) {
        if (this.checkbox[key]) allData.push(this.shippingList[key].data)
      }

      this.$axios({
        url: 'front/set/app/uLogisticsThirdData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          lthird: 1, // 綠界
          sid: this.setting.sid,
          hashkey: this.setting.hashkey,
          hashiv: this.setting.hashiv,
          alldata: JSON.stringify(allData),
          rshit: this.setting.rshit,
          rcity: this.setting.rcity,
          rarea: this.setting.rarea,
          rzipcode: this.setting.rzipcode,
          raddress1: this.setting.raddress1,
          uname: this.setting.uname,
          mp: this.setting.mp,
        },
      }).then((res) => {
        loading.close()
        if (res.data.Success) {
          this.loadSetting()
          this.$myVS.openNoti(res.data.Message, '', undefined)
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 選擇縣市時，改變鄉鎮市區和郵遞區號
    cityHandler(e) {
      console.log(e)
      const city = e
      let cityObj = this.TW_districts.find((item) => item.name === city)
      this.setting.rarea = cityObj.districts[0].name
      this.setting.rzipcode = cityObj.districts[0].zip
    },
    // 選擇鄉鎮市區時，改變郵遞區號
    districtHandler(e) {
      const city = this.setting.rcity
      let obj = this.TW_districts.find((item) => item.name === city).districts.find((district) => district.name === e)
      this.setting.rzipcode = obj.zip
    },
  },
}
</script>

<style lang="scss" scoped>
.wrap {
  @apply flex flex-col sm:flex-row sm:space-x-10 space-y-4 sm:space-y-0;
}

.leftBlock {
  @apply w-full sm:w-1/3 space-y-4 flex-shrink-0;
}

.rightBlock {
  @apply flex-grow h-fit bg-white p-4 rounded-xl space-y-6;
}

// .list_item {
//   @apply ml-0 relative px-4;

//   &::before {
//     content: '';
//     position: absolute;
//     width: 7px;
//     height: 7px;
//     left: 0px;
//     top: 50%;
//     transform: translateY(-50%);
//     border-radius: 100%;
//     background: var(--gray-border);
//   }
// }
</style>
