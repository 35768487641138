export default {
  methods: {
    // 送貨方式第三方
    lthirdMixin(val) {
      if (val === 0) return '無'
      if (val === 1) return '綠界科技'
      if (val === 2) return 'ezShip 台灣便利配'
    },
    // 送貨方式
    logisticsMixin(val) {
      if (val === 0) return '無'
      if (val === 1) return '7-ELEVEN交貨便'
      if (val === 2) return '全家店到店'
      if (val === 3) return '萊爾富店到店'
      if (val === 4) return 'OK店到店'
      if (val === 5) return '黑貓宅急便'
      if (val === 6) return '賣家宅配'
      if (val === 7) return '海外配送'
    },
    ptermsMixin(val) {
      if (val === 0) return '未啟用'
      if (val === 6) return '啟用中'
    },
  },
}
